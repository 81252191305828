import { RiArrowRightDoubleFill } from 'react-icons/ri'
import {MdKeyboardDoubleArrowDown} from 'react-icons/md'
import tercerizado from '../img/tercerizados.png';
import { useState } from 'react';

const ServiciosTercerizados = () => {
    const [item1, setItem1] = useState(false)
    const [item2, setItem2] = useState(false)
    const [item3, setItem3] = useState(false)

    const handleItem1 = () => {
        setItem1(!item1);
        setItem2(false);
        setItem3(false)
    }

    const handleItem2 = () => {
        setItem1(false);
        setItem2(!item2);
        setItem3(false)
    }

    const handleItem3 = () => {
        setItem1(false);
        setItem2(false);
        setItem3(!item3)
    }

    return (
        <section className='section-servtercer overflow-hidden'>
            <div className='container'>
                <div className='w-75 m-auto'>
                    <p>Servicios Tercerizados Outsourcing</p>
                    <h2>
                        <p className='mb-0'>¿Por qué su empresa necesita</p>
                        <p>un <span className='elementor-red'>Departamento IT?</span></p>
                    </h2>
                    <p className='mb-0'>Si duda en contratar un servicio de Soporte IT debido a los elevados</p>
                    <p className='mb-0'>costos, nuestras modalidades en Planes de Soporte en Informática</p>
                    <p><strong>Remoto y Presencial</strong> serán una excelente opción para usted.</p>
                    <ul className='mb-4 elementor-listtercer'>
                        <li className='px-2 py-3'>
                            <div onClick={handleItem1} role='button' className='d-flex gap-2'>
                                <span className='elementor-red'>
                                    <RiArrowRightDoubleFill className={`${item1 ? 'd-none':'animate-list'}`}/>
                                    <MdKeyboardDoubleArrowDown className={`${item1 ? 'animate-list':'d-none'}`}/>
                                </span>
                                <p className='mb-0'>Ahorre tiempo y dinero</p>
                            </div>
                            <div className={`px-2 ${item1 ? 'animate-list':'d-none'}`}>
                                <p>Invertir en un proveedor de servicios de alta calidad puede ayudarlo a aumentar el retorno de su inversión (ROI).</p>
                                <p>Al contar con un aliado estratégico en el Área Informática, toma la sabia decisión de estar preparado para las fallas técnicas en sus dispositivos de informática.</p>
                                <p>No solo obtiene soluciones rápidas, sino también constantes cursos de acción y conocimiento que le proporcionará acciones preventivas para tomar buenas decisiones relacionadas a gestión de recursos y preservación de su información.</p>
                            </div>
                        </li>
                        <li className='px-2 py-3'>
                            <div onClick={handleItem2} role='button' className='d-flex gap-2'>
                                <span className='elementor-red'>
                                    <RiArrowRightDoubleFill className={`${item2 ? 'd-none':'animate-list'}`}/>
                                    <MdKeyboardDoubleArrowDown className={`${item2 ? 'animate-list':'d-none'}`}/>
                                </span>
                                <p className='mb-0'>Ventajas ante sus competidores</p>
                            </div>
                            <div className={`px-2 ${item2 ? 'animate-list':'d-none'}`}>
                                <p>Cuando invierte en un Proveedor de Servicios IT competente, obtiene el beneficio de una operación eficiente de sus ordenadores, servidores y redes. Estos servicios impactan directamente en una mejora en la atención a sus clientes e incrementa la rentabilidad.</p>
                            </div>
                        </li>
                        <li className='px-2 py-3'>
                            <div onClick={handleItem3} role='button' className='d-flex gap-2'>
                                <span className='elementor-red'>
                                    <RiArrowRightDoubleFill className={`${item3 ? 'd-none':'animate-list'}`}/>
                                    <MdKeyboardDoubleArrowDown className={`${item3 ? 'animate-list':'d-none'}`}/>
                                </span>
                                <p className='mb-0'>Enfóquese en su Giro de Negocio</p>
                            </div>
                            <div className={`px-2 ${item3 ? 'animate-list':'d-none'}`}>
                                <p>Olvide las fallas técnicas y oriente su operación hacia lo que hace mejor.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='elementor-imgtercer'>
                    <picture>
                        <img className='img-fluid' src={tercerizado} alt="tercerizado"/>
                    </picture>
                </div>
            </div>
        </section>
    )
}

export default ServiciosTercerizados;