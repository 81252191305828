import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect, useEffect } from "react";
import Main from "./components/Main";
import StickyFooter from "./components/StickyFooter";
import Cotizar from "./components/Cotizar";
import Aos from "aos";

function App() {
  useEffect(() => {
    Aos.init({duration: 3000});
  },[]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Main/>} />
          <Route path="/cotizar" element={<Cotizar/>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Wrapper>
      <StickyFooter/>
    </>
  );
}

export default App;
