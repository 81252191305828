import { MdArrowForwardIos } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';

const ServiciosTop = () => {
    return (
        <section className='section-sertop position-relative overflow-hidden'>
            <div className='elementor-backgroun-sertop'></div>
            <div className='elementor-skew-sertop'></div>
            <div  className='sertop-transform'>
                <div className='container'>
                    <div className='w-75 m-auto'>
                        <div className='d-flex cont-sertops'>
                            <div className='w-50 d-flex flex-column justify-content-center'>
                                <div className='d-flex gap-1 align-items-baseline'>
                                    <p className='text-uppercase'>servicio top</p>
                                    <span className='elementor-yellow'><AiFillStar/></span>
                                    <span className='elementor-yellow'><AiFillStar/></span>
                                    <span className='elementor-yellow'><AiFillStar/></span>
                                    <span className='elementor-yellow'><AiFillStar/></span>
                                    <span className='elementor-yellow'><AiFillStar/></span>
                                </div>
                                <h2>
                                    <p className='mb-0'>Departamento de</p>
                                    <p className='mb-0'>Informática Outsourcing</p>
                                </h2>
                            </div>
                            <div className='w-50 elementor-tops'>
                                <ul>
                                    <li>
                                        <div className='d-flex gap-2'>
                                            <span className='elementor-red'><MdArrowForwardIos/></span>
                                            <p>Evaluación de Riesgos y Vulnerabilidades</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex gap-2'>
                                            <span className='elementor-red'><MdArrowForwardIos/></span>
                                            <p>Servicios de Informática Gestionados</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex gap-2'>
                                            <span className='elementor-red'><MdArrowForwardIos/></span>
                                            <p>Redes Informáticas</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex gap-2'>
                                            <span className='elementor-red'><MdArrowForwardIos/></span>
                                            <p>Soluciones IT alojadas en Nube</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex gap-2'>
                                            <span className='elementor-red'><MdArrowForwardIos/></span>
                                            <p>Planes de Continuidad de Negocio</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex gap-2'>
                                            <span className='elementor-red'><MdArrowForwardIos/></span>
                                            <p>Implementación de Firewall</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='elementor-top-desc d-flex justify-content-center'>
                            <p className='w-75 desc-sertop text-justify'>
                                En el mundo de hoy, es poco eficiente administrar un negocio sin tener Servicios de Soporte IT gestionados. 
                                No importa si es una empresa unipersonal o una Corporación Multinacional, la gestión de sus recursos de 
                                informática es crucial si desea llevar al siguiente nivel su empresa.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiciosTop