import { useState } from 'react';

const Preguntas = () => {
    const [item1, setItem1] = useState(false)
    const [item2, setItem2] = useState(false)
    const [item3, setItem3] = useState(false)
    const [item4, setItem4] = useState(false)
    const [item5, setItem5] = useState(false)

    const handleItem1 = () => {
        setItem1(!item1);
        setItem2(false);
        setItem3(false);
        setItem4(false);
        setItem5(false);
    }

    const handleItem2 = () => {
        setItem1(false);
        setItem2(!item2);
        setItem3(false);
        setItem4(false);
        setItem5(false);
    }

    const handleItem3 = () => {
        setItem1(false);
        setItem2(false);
        setItem3(!item3);
        setItem4(false);
        setItem5(false);
    }

    const handleItem4 = () => {
        setItem1(false);
        setItem2(false);
        setItem3(false);
        setItem4(!item4);
        setItem5(false);
    }

    const handleItem5 = () => {
        setItem1(false);
        setItem2(false);
        setItem3(false);
        setItem4(false);
        setItem5(!item5);
    }

    return (
        <section className="section-preguntas overflow-hidden">
            <div className="container">
                <div className="w-75 m-auto mb-5">
                    <div className="text-center">
                        <p className="mb-0 fs-2 text-uppercase fw-semibold">preguntas frecuentes</p>
                        <p className="fs-5 fw-medium">Preguntas y respuestas más frecuentes</p>
                    </div>
                    <ul className="d-flex flex-column row-gap-4 text-center ps-0 elementor-preguntas-ul">
                        <li onClick={handleItem1} role='button'>
                            <span>¿Qúe es Soporte IT?</span>
                        </li>
                        <div className={`ms-2 text-start ${item1 ? ' anitaion-show-question' : " d-none"}`}>
                            <p>
                                El soporte IT es una forma de ayuda técnica proporcionada a negocios, empresas, escuelas o incluso universidades. Una empresa como YETI Advisers brinda ayuda y soporte técnico a las organizaciones para resolver cualquier falla relacionada con los recursos informáticos tales como: Computadoras, Servidores y Dispositivos de Red.
                            </p>
                        </div>
                        <li onClick={handleItem2} role='button'>
                            <span>¿Qúe son los Servicios de Informática administrados?</span>
                        </li>
                        <div className={`ms-2 text-start ${item2 ? ' anitaion-show-question' : " d-none"}`}>
                            <p>
                                Un Servicio de Informática Administrado es cuando una empresa cuenta con el expertise de numerosos escenarios en el área IT y presta el servicio a otra organización de manera outsourcing.
                            </p>
                            <p>
                                La empresa de IT brinda gestión de recursos de informática, soporte técnico y desarrollo, soluciones en servidores web, entre otros servicios.
                            </p>
                            <p>
                                Cada vez son más las empresas que deciden contratar una organización outsourcing para gestionar su departamento IT, sustituyendo así a los equipos internos.
                            </p>
                        </div>
                        <li onClick={handleItem3} role='button'>
                            <span>¿En qué le ayuda a su Empresa?</span>
                        </li>
                        <div className={`ms-2 text-start ${item3 ? ' anitaion-show-question' : " d-none"}`}>
                            <p>
                                Los servicios IT ayudan a administrar y supervisar sus recursos informáticos. Podemos ayudarle con la instalación, el mantenimiento y el buen funcionamiento de toda su infraestructura informática (Computadoras, Servidores y Redes Informáticas).
                            </p>
                        </div>
                        <li onClick={handleItem4} role='button'>
                            <span>¿Cómo puede un proveedor de Servicios de Informática ayudar a aumentar los ingresos de su negocio?</span>
                        </li>
                        <div className={`ms-2 text-start ${item4 ? ' anitaion-show-question' : " d-none"}`}>
                            <p>
                                Al contar con una gestión eficiente de recursos tecnológicos en su empresa, se incrementa la ventaja competitiva para su organización, los servicios de atención al cliente satisfactorios dan como resultado mayores ingresos y más recomendaciones para su marca.
                            </p>
                        </div>
                        <li onClick={handleItem5} role='button'>
                            <span>¿Por qué es importante una empresa de Servicios de Soporte IT?</span>
                        </li>
                        <div className={`ms-2 text-start ${item5 ? ' anitaion-show-question' : " d-none"}`}>
                            <p>
                                Una empresa de servicios IT se encargará de la gestión de recursos informáticos de su empresa. De esta manera, los dueños y gerentes de negocios obtienen más tiempo para concentrarse en sus estrategias principales.
                            </p>
                            <p>
                                Con el soporte IT las 24 horas, los 7 días de la semana, no tiene que preocuparse por fallas en la red o fallas en sus ordenadores. YETI Advisers como proveedor de servicios IT, se encargará de brindarle solución a fallas técnicas, mantenimientos preventivos periódicamente, asesoría en nuevos proyectos de inversión relacionados al área IT y de la mano con estrategias planificadas brindará planes de contingencia para la preservación de su información.
                            </p>
                        </div>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Preguntas