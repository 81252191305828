import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { FaBars } from 'react-icons/fa';

import logo from '../img/logo.png';

const StickyHeader = () => {
    //const [displayProd, setDisplayProd] = useState(false);
    //const [mobileHead, setMobileHead] = useState(false);
    const [head, setHead] = useState(false);

    const heandleHead = () => {
        setHead(!head)
    }

    //const handleShowSubMenu = () => {
    //    setDisplayProd(true)
    //}
    //const handleHideSubMenu = () => {
    //    setDisplayProd(false)
    //}
    //const handleMenuMobile = () => {
    //    setMobileHead(!mobileHead)
    //}

    return(
        <header className='header-elementor w-100 d-flex justify-content-between align-items-center py-4 px-4 position-relative'>
            <NavLink to='/'>   
                <figure>
                    <img className='w-50 h-25' src={logo} alt="YETI LOGO"/>
                </figure>
            </NavLink>
            <ul className='d-flex px-4 header-pc'>
                {/*<li>
                    <NavLink to="/">Inicio</NavLink>
                </li>
                <li onMouseEnter={handleShowSubMenu} onMouseLeave={handleHideSubMenu} className={`${displayProd? "animation-show" : "" }`}>
                    <span role='button'>Servicios</span>
                    <div className='d-none z-3 position-absolute subheader-elementor'>
                        <ul className='d-flex flex-column px-4 ps-0 py-2'>
                            <li>
                                <NavLink to="/soporte">Soporte IT</NavLink>
                            </li>
                        </ul>
                    </div>
                </li>*/}
                <li>
                    <NavLink to="/soporte">Soporte IT</NavLink>
                </li>
                <li className='btn-cotizar exception-btn-cotizar'>
                    <NavLink to="/cotizar">Cotizar</NavLink>
                </li>
            </ul>

            {/****************** Icono hamburguesa *************************/}
            <div onClick={heandleHead} role='button' className='d-none icon-hamburguer z-3'>
                <FaBars  size={20}/>
            </div>
            {/****************** Menú mobil *************************/}
            <div className={`${head ? 'header-open ' : 'header-close'} elementor-mobile-header`}>
                <ul className='h-100 w-100 text-center ps-0'>
                    {/*<li onClick={heandleHead} className='py-2'>
                        <NavLink to='/'>Inicio</NavLink>
                    </li>
                    <li role='button' onClick={handleMenuMobile} className={`${mobileHead ? 'animation-show' : '' }`}>
                        <span>Servicios</span>
                        <div className='d-none header-service-mobile'>
                            <ul className='d-flex flex-column px-3'>
                                <li onClick={heandleHead} className='py-2'>
                                    <NavLink to="/">Soporte IT</NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>*/}
                    <li onClick={heandleHead} className='py-2'>
                        <NavLink to="/">Soporte IT</NavLink>
                    </li>
                    <li onClick={heandleHead} className='py-4'>
                        <span className='btn-cotizar p-3'>
                            <NavLink to='/cotizar'>Cotizar</NavLink>
                        </span>
                    </li>
                </ul>
            </div>
        </header>
    )
}

export default StickyHeader