const useDescripcion = ({contDesc, indice, display, width}) => {
    const createData = () => {
        if (Array.isArray(contDesc[indice])){
            return contDesc[indice].map((data,i) => {
                return(
                    <span key={i}>{data}</span>
                )
            })
        }
        else{
            return contDesc[indice]
        }
    }
    return <div className={`${width > 768? 'mt-5': 'my-2'} ${display? 'serv-show': 'd-none'}`}>
                <p className='text-center mb-0'>
                    { createData() }
                </p>
            </div>
}

export default useDescripcion