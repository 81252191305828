import { NavLink } from 'react-router-dom';
import ServiciosTercerizados from './ServiciosTercerizados';
import ServiciosBrindados from './ServiciosBrindados';
import Cuestionamiento from './Cuestionamiento';
import ServiciosTop from './ServiciosTop';
import StickyHeader from './StickyHeader';
import Preguntas from './Preguntas';
import Nosotros from './Nosotros';

const Main = () => {

    return(
        <>
        {/************************** Section Main *************************/}
            <section className='section-main mb-5 position-relative overflow-hidden p-0'>
                <div className='picture-overlay-data'></div>
                <StickyHeader/>
                <div className='sermain-transform mb-5'>
                    <div className='d-flex'>
                        <div className='container data-elementor-main'>
                            <p className='text-uppercase elementor-subtitles'>Servicio Outsourcing en Guatemala</p>
                            <h1 className="card-title title text-start text-uppercase">
                                <p className='mb-0'>servicios de</p>
                                <p className='mb-0 mr-top-negative'>informática</p>
                                <p className='mr-top-negative'>administrados</p>
                            </h1>
                            <p className='mb-0 elementor-subtitles'>Subcontrate de manera confiable el</p>
                            <p className='elementor-subtitles'><span className='elementor-red'>Departamento IT</span> en su organización</p>
                            <NavLink to="/cotizar" className="btn-contactanos text-uppercase elementor-subtitles">
                                contáctenos
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='elementor-skew'></div>
            </section>

            {/************************** Section Service Top *************************/}
            <ServiciosTop/>

            {/************************** Section About *************************/}
            <Nosotros/>

            {/************************** Section servicios tercerizados *************************/}
            <ServiciosTercerizados/>    

            {/************************** Section servicios brindados *************************/}
            <ServiciosBrindados/>

            {/************************** Section cuestionamiento *************************/}
            <Cuestionamiento/>

            {/************************** Section preguntas *************************/}
            <Preguntas/>
        </>
    )
}

export default Main