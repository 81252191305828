import { useState } from "react"
import StickyHeader from "./StickyHeader"
import response from "../helpers/sentMail";

const Cotizar = () => {
    const [preSend, setPreSend] = useState(false);
    const [SendMail, setSendMail] = useState(false);
    const [allValues, setAllValues] = useState(true);
    const [inputCompany, setInputCompany] = useState('');
    const [inputMensaje, setInputMensaje] = useState('');
    const [inputPhone, setInputPhone] = useState('');
    const [inputUsers, setInputUsers] = useState('');
    const [inputName, setInputName] = useState('');
    const [inputCity, setInputCity] = useState('');

    const handleInputName = (e) => {
        setInputName(e.target.value);
    }
    const handleInputComapny = (e) => {
        setInputCompany(e.target.value);
    }
    const handleInputCity = (e) => {
        setInputCity(e.target.value);
    }
    const handleInputPhone = (e) => {
        setInputPhone(e.target.value);
    }
    const handleInputMensaje = (e) => {
        setInputMensaje(e.target.value);
    }
    const handleInputUsers = (e) => {
        setInputUsers(e.target.value);
    }

    const handleSendMail = async () => {
        let data = {
            'name': inputName,
            'company': inputCompany,
            'city': inputCity,
            'phone': inputPhone,
            'mensaje': inputMensaje,
            'users': inputUsers
        }
        
        const res = await response(data)
        
        if(res.status === 200){
            setPreSend(false)
            setSendMail(true);
            setAllValues(true)
            setTimeout(() => {
                setSendMail(false);
            }, 3000);
        }
        else{
            setSendMail(false);
        }
    }

    const handleValidation = () => {
        if (inputName !== '' && inputCompany !== '' && inputCity !== '' && inputPhone !== '' && inputUsers !== ''){
            setAllValues(true)
        }
        else{
            setAllValues(false)
            setTimeout(() => {
                setAllValues(true);
            }, 3000);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (allValues){
            setPreSend(true)
            await handleSendMail()
            setInputCompany('');
            setInputMensaje('');
            setInputPhone('');
            setInputUsers('');
            setInputName('');
            setInputCity('');
        }
    }

    return (
        <>
            <section className="elementor-other-header">
                <StickyHeader/>
            </section>
            <section className="my-5 overflow-hidden">
                <div className=" container">
                    <div className="d-flex justify-content-center">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="inputName" className="form-label elementor-label">Nombre <span className="elementor-red">*</span></label>
                                <input type="text" className="form-control elementor-input" id="inputName" placeholder="Nombre Apellido" value={inputName} onChange={handleInputName}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inputCompany" className="form-label elementor-label">Empresa <span className="elementor-red">*</span></label>
                                <input type="text" className="form-control elementor-input" id="inputCompany" placeholder="Organización de donde nos saluda" value={inputCompany} onChange={handleInputComapny}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inputCity" className="form-label elementor-label">Ciudad en donde se ubica <span className="elementor-red">*</span></label>
                                <input type="text" className="form-control elementor-input" id="inputCity" placeholder="Mixco, Villa Nueva, Guatemala" value={inputCity} onChange={handleInputCity}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inputPhone" className="form-label elementor-label">Teléfono <span className="elementor-red">*</span></label>
                                <input type="tel" className="form-control elementor-input" id="inputPhone" placeholder="0000-0000" value={inputPhone} onChange={handleInputPhone}/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inputMensaje" className="form-label elementor-label">Mensaje</label>
                                <textarea type="text" className="form-control elementor-input" id="inputMensaje" placeholder="Si lo deseas, escribe acá tú solicitud" value={inputMensaje} onChange={handleInputMensaje}></textarea>
                            </div>
                            <fieldset className="mb-3">
                                <legend className="form-label elementor-label">Número de usuarios en su Organización <span className="elementor-red">*</span></legend>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="radio" name="radiosUsers" id="radiosUsers1" value="15" checked={inputUsers === '15'} onChange={handleInputUsers}/>
                                    <label className="form-check-label elementor-label fw-medium" htmlFor="radiosUsers1">1 a 15</label>
                                </div>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="radio" name="radiosUsers" id="radiosUsers2" value="35" checked={inputUsers === '35'} onChange={handleInputUsers}/>
                                    <label className="form-check-label elementor-label fw-medium" htmlFor="radiosUsers1">16 a 35</label>
                                </div>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="radio" name="radiosUsers" id="radiosUsers3" value="50" checked={inputUsers === '50'} onChange={handleInputUsers}/>
                                    <label className="form-check-label elementor-label fw-medium" htmlFor="radiosUsers1">36 a 50</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="radiosUsers" id="radiosUsers4" value="51" checked={inputUsers === '51'} onChange={handleInputUsers}/>
                                    <label className="form-check-label elementor-label fw-medium" htmlFor="radiosUsers1">Más de 50</label>
                                </div>
                            </fieldset>

                            <div className={`alert alert-danger d-flex align-items-center ${!allValues ? 'show-incomplete': 'd-none' }`} role="alert">
                                <div>
                                    No se han completado todos los campos... ¡Por favor completa todos los campos!
                                </div>
                            </div>

                            <div className={`alert alert-warning ${preSend ? 'show-incoming': 'd-none' }`} role="alert">
                                Enviando correo...
                            </div>

                            <div className={`alert alert-success ${SendMail ? 'show-success': 'd-none' }`} role="alert">
                                Gracias, con gusto nos pondremos en contacto
                            </div>

                            <button onClick={handleValidation} className={`elementor-btn-cotiza ${preSend ? 'd-none' : ''}`}>Enviar Mensaje</button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cotizar