import { AiOutlineCopyrightCircle } from 'react-icons/ai'
import { BsFacebook, BsInstagram, BsTiktok, BsTwitter, BsLinkedin, BsYoutube } from "react-icons/bs";
import yeti from '../img/yeti.png';
import { NavLink } from 'react-router-dom';

const StickyFooter = () => {
    return(
        <>
            <footer>
                <section className="section-footer">
                    <div className="container">
                        <div className="d-flex flex-wrap gap-5 justify-content-center">
                            <div className="">
                                <p><strong>Información de Contacto</strong></p>

                                <p className="mb-0"><strong>Dirección</strong></p>
                                <p className='mb-0'>595 Madison Ave 8th Floor.</p>
                                <p>New York, NY 10022</p>

                                {/*<p className="mb-0"><strong>Teléfonos</strong></p>
                                <p>(718) 569-9747</p>*/}

                                <p className="mb-0"><strong>Email</strong></p>
                                <p>info@yetiadvisers.com</p>
                            </div>
                            <div className="d-flex justify-content-end content-footer-logo">
                                <div>
                                    <NavLink to='/'>
                                        <picture>
                                            <img className='img-foot-logo img-fluid' src={yeti} alt='Yeti Advisers'/>
                                        </picture>
                                    </NavLink>
                                    <div>
                                        <p className='mb-0'>Sé parte de nuestra comunidad</p>
                                        <div className='d-flex gap-3'>
                                            <NavLink to="https://www.facebook.com/YetiAdvisers/" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsFacebook/></span></NavLink>
                                            <NavLink to="https://www.instagram.com/yetiadvisers/" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsInstagram/></span></NavLink>
                                            <NavLink to="" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsTiktok/></span></NavLink>
                                            <NavLink to="https://twitter.com/yetiadvisers?lang=es" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsTwitter/></span></NavLink>
                                            <NavLink to="https://gt.linkedin.com/company/yeti-advisers" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsLinkedin/></span></NavLink>
                                            <NavLink to="https://www.youtube.com/channel/UC5cDlhjtOQ5nlkilEn5NW4w" rel='noopener noreferrer nofollow' target='_blank'><span className='elementor-redes'><BsYoutube/></span></NavLink>
                                        </div>
                                        <p className='mb-0'>@yetiadvisers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="elementor-copy text-center">
                    <p className="mb-0 py-3"><span><AiOutlineCopyrightCircle/></span> 2024 YETI Advisers, S.A. Todos los derechos reservados.</p>
                </div>
            </footer>
        </>
    )
}

export default StickyFooter