import { useState } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import { BiSolidRightArrow } from 'react-icons/bi'
import { NavLink } from "react-router-dom";

const Nosotros = () => {
    const [item1, setItem1] = useState(false)
    const [item2, setItem2] = useState(false)
    const [item3, setItem3] = useState(false)

    const handleItem1 = () => {
        setItem1(!item1);
        setItem2(false);
        setItem3(false)
    }

    const handleItem2 = () => {
        setItem1(false);
        setItem2(!item2);
        setItem3(false)
    }

    const handleItem3 = () => {
        setItem1(false);
        setItem2(false);
        setItem3(!item3)
    }

    return(
        <section className='section-about overflow-hidden'>
            <div className='container'>
                <div className="w-75 m-auto">
                    <ul className='mb-4 row ul-elementor-about'>
                        <li className='px-2 col grid justify-content-center'>
                            <p className='mb-0'>Soluciones Prontas</p>
                            <div onClick={handleItem1} role='button' className='d-flex gap-2'>
                                <span className="elementor-red">
                                    <BiSolidRightArrow className={`${item1 ? 'd-none':'animate-list'}`}/>
                                    <BiSolidDownArrow className={`${item1 ? 'animate-list':'d-none'}`}/>
                                </span>
                                <p>Leer Más</p>
                            </div>
                            <div className={`px-2 ${item1 ? 'animate-list':'d-none'}`}>
                                <p>
                                    Nuestros consultores le brindarán asistencia lo más pronto posible. Brindamos soluciones y estrategias dentro de un marco de tiempo establecido ¡Sin Demoras!
                                </p>
                            </div>
                        </li>
                        <li className='px-2 col grid justify-content-center'>
                            <p className='mb-0'>Especialistas en Soporte IT</p>
                            <div onClick={handleItem2} role='button' className='d-flex gap-2'>
                                <span className="elementor-red">
                                    <BiSolidRightArrow className={`${item2 ? 'd-none':'animate-list'}`}/>
                                    <BiSolidDownArrow className={`${item2 ? 'animate-list':'d-none'}`}/>
                                </span>
                                <p>Leer Más</p>
                            </div>
                            <div className={`px-2 ${item2 ? 'animate-list':'d-none'}`}>
                                <p>Más de 10 años en Guatemala y Estados Unidos brindando servicios de Soporte IT a nuestros clientes de sector público y privado.</p>
                                <p>Consultores IT certificados por Google, cada uno con su propio conjunto de habilidades únicas.</p>
                                <p>Seleccionamos un experto en IT en función del escenario de su organización.</p>
                            </div>
                        </li>
                        <li className='px-2 col grid justify-content-center'>
                            <p className='mb-0'>Servicio Enfocado y Flexible</p>
                            <div onClick={handleItem3} role='button' className='d-flex gap-2'>
                                <span className="elementor-red">
                                    <BiSolidRightArrow className={`${item3 ? 'd-none':'animate-list'}`}/>
                                    <BiSolidDownArrow className={`${item3 ? 'animate-list':'d-none'}`}/>
                                </span>
                                <p>Leer Más</p>
                            </div>
                            <div className={`px-2 ${item3 ? 'animate-list':'d-none'}`}>
                                <p>Analizamos el escenario de su organización en temas de Gestión de Soporte IT y con base a ello brindamos Soluciones viables para su empresa en Guatemala.</p>
                            </div>
                        </li>
                    </ul>
                    <div className='elementor-top-desc'>
                        <p className='text-uppercase text-center'>
                            ¡Obtén sin costo un <strong><NavLink to='/cotizar'>informe preliminar</NavLink></strong> de tu organización!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Nosotros;