import imgSoporte from '../img/soporte.png';
import imgSeguridad from '../img/seguridad.png';
import imgFirewall from '../img/firewall.png';
import imgPropuestas from '../img/propuestas.png';
import { useState } from 'react';
import Descripcion from '../hooks/useDescripcion';
import useScreenSize from '../hooks/useScreenSize';

const ServiciosBrindados = () => {
    const {width} = useScreenSize()
    const [indice,setIndice] = useState(-1)
    const [display,setDisplay] = useState(false)

    const contDesc = [
        ["Asistencias técnicas ilimitadas de forma ",
            <span className='elementor-red'>remota y presencial</span>,
            " a sus ordenadores, servidores y dispositivos de red para que su operación funcione de manera eficiente, sin atrasos. Atención en toda Guatemala.",
        ],
        ["¿Quieres mantener tus oficinas alejadas de las amenazas de hackers profesionales? Con la ayuda de nuestros ",
            <span className='elementor-red'>Consultores IT</span>,
            " puede ignorar estos ataques dirigidos, a través de nuestros Servicios de Informática Outsourcing y protocolos planificados previamente, reducimos las amenazas que pueda presentar su información."
        ],
        'Un Firewall es un buen aliado en cualquier empresa donde existen aquellas personas que utilizan de manera indiscriminada la gran variedad de sitios de internet y a su vez comprometen la integridad y seguridad de la información, es de mencionar que los principales focos de ataques y amenazas son normalmente las diferentes redes sociales, sitios de pornografía, streaming, noticias, entre otros; Todo esto sin olvidar el costo o gasto sombra que representa el personal al momento de permanecer en estos sitios en horario laboral.',
        ['Tener a YETI Advisers como aliado estratégico es tener al alcance ',
            <span className='elementor-red'>Soluciones Integrales</span>,
            ' que apoyen el crecimiento de su organización; esto lo logramos a través de nuestros Planes de Soporte IT Mensuales Sin Contratos que contemplan servicios preventivos, correctivos y cuyo propósito es institucionalizar el Departamento de Informática en su empresa. Atención en toda Guatemala.'
        ],
    ]

    const handleShowSoport = () =>{
        setIndice(0)
        setDisplay(true)
    }
    const handleShowSeguridad = () =>{
        setIndice(1)
        setDisplay(true)
    }
    const handleShowFirewall = () =>{
        setIndice(2)
        setDisplay(true)
    }
    const handleShowPropuesta = () =>{
        setIndice(3)
        setDisplay(true)
    }
    const handleHideDesc = () => {
        setIndice(-1)
        setDisplay(false)
    }

    return (
        <section className='section-servbrind overflow-hidden'>
            <div className='container'>
                <div className='w-75 mx-auto'>
                    <h2>
                        <p className='text-center text-uppercase fw-bold'>servicios de <span className='elementor-red'>informática</span> que brindamos</p>
                    </h2>
                    <div className='d-flex gap-4 text-center contenedor-servicios-mobile'>
                        <div onMouseEnter={handleShowSoport} onMouseLeave={handleHideDesc} className='border w-25 serv-hover'>
                            <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100 p-3'>
                                <picture>
                                    <img src={imgSoporte} alt='Soporte Técnico'/>
                                </picture>
                                <p> Soporte Técnico</p>
                            </div>
                        </div>
                        { (width <= 768 && indice === 0) ? <Descripcion contDesc={contDesc} indice={indice} display={display}/> : ''}
                        <div onMouseEnter={handleShowSeguridad} onMouseLeave={handleHideDesc} className='border w-25 serv-hover'>
                            <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100 p-3'>
                                <picture>
                                    <img src={imgSeguridad} alt='Seguridad Informática'/>
                                </picture>
                                <p> Seguridad Informática</p>
                            </div>
                        </div>
                        { (width <= 768 && indice === 1) ? <Descripcion contDesc={contDesc} indice={indice} display={display} width={width}/> : ''}
                        <div onMouseEnter={handleShowFirewall} onMouseLeave={handleHideDesc} className='border w-25 serv-hover'>
                            <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100 p-3'>
                                <picture>
                                    <img src={imgFirewall} alt='Implementación de Firewall'/>
                                </picture>
                                <p>Implementación de Firewall</p>
                            </div>
                        </div>
                        { (width <= 768 && indice === 2) ? <Descripcion contDesc={contDesc} indice={indice} display={display} width={width}/> : ''}
                        <div onMouseEnter={handleShowPropuesta} onMouseLeave={handleHideDesc} className='border w-25 serv-hover'>
                            <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100 p-3'>
                                <picture>
                                    <img src={imgPropuestas} alt='Propuestas Integrales'/>
                                </picture>
                                <p>Propuestas Integrales</p>
                            </div>
                        </div>
                        { (width <= 768 && indice === 3) ? <Descripcion contDesc={contDesc} indice={indice} display={display} width={width}/> : ''}
                    </div>
                    { width > 768 ? <Descripcion contDesc={contDesc} indice={indice} display={display} width={width}/> : ''}
                    {/** 
                    {/*************************************Soporte*****************************************
                    <div className='d-flex justify-content-center reverse-column gap-2 my-5 mb-2'>
                        <div className='w-75 d-flex justify-content-center align-self-center'>
                            <picture className=''>
                                <img className=' h-auto' src={imgSoporte} alt='Soporte Técnico'/>
                            </picture>
                        </div>
                        <div className='d-flex flex-column align-self-center'>
                            <h2 className='fw-semibold'>Soporte Técnico</h2>
                            <p className='text-justify-desc'>Asistencias técnicas ilimiatadas de forma <span className='elementor-red'>remota y presencial</span> a sus ordenadores, servidores y dispositivos de red para que su operación funcione de manera eficiente, sin atrasos.</p>
                        </div>
                    </div>
                    /*************************************Seguridad*****************************************
                    <div className='d-flex justify-content-center column-wrap gap-2 my-5'>
                        <div className='d-flex flex-column align-items-end align-self-center'>
                            <h2 className='fw-semibold'>Seguridad Informática</h2>
                            <p className='text-justify-desc'>¿Quieres mantener tus oficinas alejadas de las amenazas de hackers profesionales? Con la ayuda de nuestros <span className='elementor-red'>Consultores IT</span> puede ignorar estos ataques dirigidos, a través de nuestros Servicios de Informática Outsourcing y protocolos planificados previamente, reducimos las amenazas que pueda presentar su información.</p>
                        </div>
                        <div className='w-75 d-flex justify-content-center align-self-center'>
                            <picture className=''>
                                <img className='h-auto' src={imgSeguridad} alt='Seguridad Informática'/>
                            </picture>
                        </div>
                    </div>
                    *************************************Firewall****************************************
                    <div className='d-flex justify-content-center reverse-column gap-2 my-5'>
                        <div className='w-75 d-flex justify-content-center align-self-center'>
                            <picture className='w-100'>
                                <img className='w-100 h-auto' src={imgFirewall} alt='Implementación de Firewall'/>
                            </picture>
                        </div>
                        <div className='d-flex flex-column align-self-center'>
                            <h2 className='fw-semibold'>Implementación de Firewall</h2>
                            <p className='text-justify-desc'>Un Firewall es un buen aliado en cualquier empresa donde existen aquellas personas que utilizan de manera indiscriminada la gran variedad de sitios de internet y a su vez comprometen la integridad y seguridad de la información, es de mencionar que los principales focos de ataques y amenazas son normalmente las diferentes redes sociales, sitios de pornografía, streaming, noticias, entre otros; Todo esto sin olvidar el costo o gasto sombra que representa el personal al momento de permanecer en estos sitios en horario laboral.</p>
                        </div>
                    </div>
                    /*************************************Propuestas*****************************************
                    <div className='d-flex justify-content-center column-wrap gap-2 my-5'>
                        <div className='d-flex flex-column align-items-end align-self-center'>
                            <h2 className='fw-semibold'>Propuestas Integrales</h2>
                            <p className='text-justify-desc'>Tener a YETI Advisers como aliado estratégico es tener al alcance <strong>Soluciones Integrales</strong> que apoyen el crecimiento de su organización; esto lo logramos a través de nuestros Planes de Soporte IT Mensuales Sin Contratos que contemplan servicios preventivos, correctivos y cuyo propósito es institucionalizar el Departamento de Informática en su empresa.</p>
                        </div>
                        <div className='w-75 d-flex justify-content-center align-self-center'>
                            <picture className='w-100'>
                                <img className='w-100 h-auto' src={imgPropuestas} alt='Propuestas Integrales'/>
                            </picture>
                        </div>
                    </div>*/}
                </div>
            </div>
        </section>
    )
}

export default ServiciosBrindados