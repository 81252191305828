import cuestionamiento from '../img/cuestionamiento.png';
const Cuestionamiento = () => {
    return (
        <section className="section-cuestion overflow-hidden">
            <div  className="container pt-5">
                <div className='text-end w-75 m-auto'>
                    <h2 className="fw-semibold fs-1">¿Sigue haciéndose cargo por su cuenta del <span className="elementor-red">Departamento IT</span> en su organización?</h2>
                        <p className='text-start'>Hacer malabares entre todos los sectores comerciales al mismo tiempo puede ser difícil.</p>
                        <p className='text-start'>Cada vez son más las corporaciones en Guatemala que recurren a YETI Advisers para la gestión de sus servicios de Informática. Algunas empresas contratan a miembros del personal para que se ocupen de fallas técnicas de informática, esto puede llevar bastante tiempo y ralentizar la eficiencia de su empresa.</p>
                        <p className='text-start'>¿Quién lo supervisa? ¿Qué expertise en informática tienen sus colaboradores?</p>
                </div>
                <div className='elementor-imgcuestio position-relative d-flex justify-content-center'>
                    <picture className='position-absolute bottom-0'>
                        <img className='img-fluid img-cuestionamiento' src={cuestionamiento} alt='Cuestionamientos'/>
                    </picture>
                </div>
            </div>
        </section>
    )
}

export default Cuestionamiento